<template>
  <div>
    <div>
      <h4>Reportes</h4>
    </div>
    <div class="d-flex mx-2 mb-2 container-select noprint">
      <span class="w-10 align-content-center noprint">Tipo</span>
      <b-form-select
        id="selected_report_type"
        v-model="selected_report_type"
        :options="report_type_options"
        class="w-90 noprint"
      >
      </b-form-select>
    </div>
    <Filters
      :max_campuses="max_campuses"
      :max_careers="max_careers"
      :max_egress_profiles="max_egress_profiles"
      :max_sections="max_sections"
      :max_matters="max_matters"
      :max_modalities="max_modalities"
      :max_periods="max_periods"
      :max_levels="max_levels"
      :max_study_units="max_study_units"
      :max_cohorts="max_cohorts"
      :max_cycles="max_cycles"
      :max_student_status="max_student_status"
      :load_cohorts="load_cohorts"
      :disabled="!selected_report_type"
      :required_selectors="required_selectors"
      :show_selectors="show_selectors"
      :clear_filters="clear_filters"
      @campuses="(x) => (campuses_ = x)"
      @careers="(x) => (careers_ = x)"
      @egress_profiles="(x) => (egress_profiles_ = x)"
      @levels="(x) => (levels_ = x)"
      @matters="(x) => (matters_ = x)"
      @periods="(x) => (periods_ = x)"
      @sections="(x) => (sections_ = x)"
      @study_units="(x) => (study_units_ = x)"
      @cohorts="(x) => (cohorts_ = x)"
      @cycles="(x) => (cycles_ = x)"
      @student_status="(x) => (student_status_ = x)"
    >
    </Filters>
    <div class="toggle-div mt-3 noprint">
      <b-button
        class="load-report-btn"
        size="sm"
        @click="fetchReport"
        :disabled="!requirementFulfilled"
        >Cargar Reporte</b-button
      >
      <b-button class="load-report-btn ml-2" size="sm" @click="clearFilters"
        >Limpiar Filtros</b-button
      >
    </div>
    <!-- {{ selected_report_type }} {{ chartdata }} {{ loading }} -->
    <template v-if="!selected_report_type || (!chartdata && !loading)">
      <div class="reports-default">
        <div class="reports-default-caption">
          Seguimiento de resultados de aprendizaje.
        </div>
        <img
          class="reports-default-image"
          src="https://i.postimg.cc/rw8KnBf2/Pngtree-learning-progress-character-vector-5776835.png"
          alt="Responsive image"
        />
      </div>
    </template>
    <LegendFilters
      v-if="selectors"
      :key="`LegendFilters-${report_key}`"
      :campuses_="selectors.selected_campuses"
      :careers_="selectors.selected_careers"
      :egress_profiles_="selectors.selected_egress_profiles"
      :levels_="selectors.selected_levels"
      :matters_="selectors.selected_matters"
      :periods_="selectors.selected_periods"
      :sections_="selectors.selected_sections"
      :study_units_="selectors.selected_study_units"
      :cohorts_="selectors.selected_cohorts"
      :cycles_="selectors.selected_cycles"
      :student_status_="selectors.selected_student_status"
      :show_selectors="show_selectors"
    ></LegendFilters>
    <AssessmentRangeSection
      v-if="
        selectors != null &&
        selected_report_type == 1 &&
        show_range_grades_section &&
        selectors.selected_matters &&
        selectors.selected_matters.length == 1 &&
        selectors.selected_sections &&
        selectors.selected_sections.length > 0
      "
      :key="report_key"
      :sections_ids="selectors.selected_sections.map((x) => x.id)"
      :egress_profile_matter="selectors.selected_matters[0]"
      :campuses_="selectors.selected_campuses"
      :egress_profiles_="selectors.selected_egress_profiles"
      :levels_="selectors.selected_levels"
      :matters_="selectors.selected_matters"
      :periods_="selectors.selected_periods"
      :sections_="selectors.selected_sections"
    ></AssessmentRangeSection>
    <AssessmentCompetenceEgrPro
      v-if="
        selected_report_type == 2 &&
        selectors != null &&
        selectors.selected_egress_profiles &&
        selectors.selected_egress_profiles.length == 1 &&
        selectors.selected_cohorts &&
        selectors.selected_cohorts.length > 0
      "
      :key="report_key"
      :egress_profile="selectors.selected_egress_profiles[0]"
      :campuses_="selectors.selected_campuses"
      :egress_profiles_="selectors.selected_egress_profiles"
      :cohorts_="selectors.selected_cohorts"
      :cycles_="selectors.selected_cycles"
      :student_status_="selectors.selected_student_status"
    ></AssessmentCompetenceEgrPro>
    <AssessmentGroupCompEgrPro
      v-if="
        selected_report_type == 3 &&
        selectors != null &&
        selectors.selected_egress_profiles &&
        selectors.selected_egress_profiles.length == 1 &&
        selectors.selected_cohorts &&
        selectors.selected_cohorts.length > 0
      "
      :key="report_key"
      :egress_profile="selectors.selected_egress_profiles[0]"
      :campuses_="selectors.selected_campuses"
      :egress_profiles_="selectors.selected_egress_profiles"
      :cohorts_="selectors.selected_cohorts"
      :cycles_="selectors.selected_cycles"
      :student_status_="selectors.selected_student_status"
    ></AssessmentGroupCompEgrPro>
    <AssessmentGroupStudyUnit
      v-if="
        selected_report_type == 4 &&
        selectors != null &&
        selectors.selected_egress_profiles &&
        selectors.selected_egress_profiles.length == 1 &&
        selectors.selected_cohorts &&
        selectors.selected_cohorts.length > 0
      "
      :key="report_key"
      :egress_profile="selectors.selected_egress_profiles[0]"
      :egress_profile_matter="selectors.selected_matters[0]"
      :cohorts_="selectors.selected_cohorts"
      :cycles_="selectors.selected_cycles"
      :periods_="selectors.selected_periods"
      :sections_="selectors.selected_sections"
    ></AssessmentGroupStudyUnit>
  </div>
</template>

<script>
import * as names from "@/store/names";
// import { mapGetters } from "vuex";
import { showLoadingAlert } from "@/utils/utils";

export default {
  name: "GeneralReport",
  components: {
    Filters: () => import("@/components/reports/Filters"),
    LegendFilters: () =>
      import("@/components/reports/TeachingReport/LegendFilters"),
    AssessmentRangeSection: () =>
      import(
        "@/components/reports/TeachingReport/AssessmentRangeSection/AssessmentRangeSection.vue"
      ),
    AssessmentCompetenceEgrPro: () =>
      import(
        "@/components/reports/TeachingReport/AssessmentCompetenceEgrPro/AssessmentCompetenceEgrPro.vue"
      ),
    AssessmentGroupCompEgrPro: () =>
      import(
        "@/components/reports/TeachingReport/AssessmentGroupCompEgrPro/AssessmentGroupCompEgrPro.vue"
      ),
    AssessmentGroupStudyUnit: () =>
      import(
        "@/components/reports/TeachingReport/AssessmentGroupStudyUnit/AssessmentGroupStudyUnit.vue"
      ),
  },
  props: {},
  data() {
    return {
      selected_report_type: null,
      max_campuses: "all",
      max_careers: "all",
      max_egress_profiles: "all",
      max_sections: "all",
      max_matters: "all",
      max_modalities: "all",
      max_periods: "all",
      max_levels: "all",
      max_study_units: "all",
      max_cohorts: "all",
      max_cycles: "all",
      max_student_status: "all",
      campuses_: null,
      careers_: null,
      egress_profiles_: null,
      levels_: null,
      matters_: null,
      periods_: null,
      sections_: null,
      study_units_: null,
      cohorts_: null,
      cycles_: null,
      student_status_: null,
      chartdata: null,
      // component_key: 0,
      report_key: 0,
      selectors: null,
      clear_filters: false,
      disable_clear_btn: false,
      required_selectors:
        "campuses careers egress_profiles matters sections levels periods study_units cohorts student_status cycles",
      show_selectors: "",
      loading: false,
      load_cohorts: false,
      show_range_grades_section: false,
    };
  },
  computed: {
    // ...mapGetters({}),
    report_type_options() {
      const options = [
        {
          value: 1,
          order: 1,
          text: `Reporte de Assessment por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )} del ${this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil de egreso"
          )}`,
        },
        {
          value: 2,
          order: 3,
          text: `Reporte de Assessment por ${this.$getVisibleNames(
            "mesh.competence",
            true,
            "Competencias"
          )} del ${this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil de egreso"
          )}`,
        },
        {
          value: 3,
          order: 4,
          text: `Reporte de Assessment grupal de logros de ${this.$getVisibleNames(
            "mesh.competence",
            true,
            "Competencias"
          )} del ${this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil de egreso"
          )}`,
        },
        {
          value: 4,
          order: 2,
          text: `Reporte de Assessment grupal de logros de ${this.$getVisibleNames(
            "teaching.ramicro",
            false,
            "RA Micro"
          )} por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
        },
      ];
      return options.sort((a, b) => a.order - b.order);
    },
    requirementFulfilled() {
      if (this.selected_report_type == 1) {
        if (!this.egress_profiles_ || !this.egress_profiles_.length)
          return false;
        // if (!this.periods_ || !this.periods_.length) return false;
        // if (!this.levels_ || !this.levels_.length) return false;
        // if (!this.cohorts_ || !this.cohorts_.length) return false;
        // if (!this.study_units_ || !this.study_units_.length) return false;
        if (!this.sections_ || !this.sections_.length) return false;
        if (!this.matters_ || !this.matters_.length) return false;
        return true;
      } else if (this.selected_report_type == 2) {
        if (!this.egress_profiles_ || !this.egress_profiles_.length)
          return false;
        if (!this.cohorts_ || !this.cohorts_.length) return false;
        if (!this.student_status_ || !this.student_status_.length) return false;
        return true;
      } else if (this.selected_report_type == 3) {
        if (!this.egress_profiles_ || !this.egress_profiles_.length)
          return false;
        if (!this.cohorts_ || !this.cohorts_.length) return false;
        if (!this.student_status_ || !this.student_status_.length) return false;
        return true;
      } else if (this.selected_report_type == 4) {
        if (!this.egress_profiles_ || !this.egress_profiles_.length)
          return false;
        if (!this.cohorts_ || !this.cohorts_.length) return false;
        if (!this.periods_ || !this.periods_.length) return false;
        if (!this.matters_ || !this.matters_.length) return false;
        if (!this.sections_ || !this.sections_.length) return false;
        return true;
      }
      // else if (this.selected_report_type == 3) {
      //   if (!this.egress_profiles_ || !this.egress_profiles_.length)
      //     return false;
      //   if (!this.levels_ || !this.levels_.length) return false;
      //   if (!this.cohorts_ || !this.cohorts_.length) return false;
      //   return true;
      // }
      return false;
    },
  },
  methods: {
    resetSettings() {
      this.max_campuses = "all";
      this.max_careers = "all";
      this.max_egress_profiles = "all";
      this.max_sections = "all";
      this.max_matters = "all";
      this.max_modalities = "all";
      this.max_periods = "all";
      this.max_levels = "all";
      this.max_study_units = "all";
      this.max_cohorts = "all";
      this.max_cycles = "all";
      this.load_cohorts = false;
      this.show_range_grades_section = false;
    },
    clearFilters() {
      this.clear_filters = true;
      setTimeout(() => {
        this.clear_filters = false;
      }, 1000);
      this.show_range_grades_section = false;
      this.chartdata = null;
      this.selectors = null;
    },
    fetchReport() {
      // this.loading = true;
      // this.report_key = 0;
      this.selectors = {
        selected_campuses:
          this.campuses_ && this.campuses_.length > 0
            ? [...this.campuses_]
            : [],
        selected_careers:
          this.careers_ && this.careers_.length > 0 ? [...this.careers_] : [],
        selected_egress_profiles:
          this.egress_profiles_ && this.egress_profiles_.length > 0
            ? [...this.egress_profiles_]
            : [],
        selected_levels:
          this.levels_ && this.levels_.length > 0 ? [...this.levels_] : [],
        selected_matters:
          this.matters_ && this.matters_.length > 0 ? [...this.matters_] : [],
        selected_periods:
          this.periods_ && this.periods_.length > 0 ? [...this.periods_] : [],
        selected_sections:
          this.sections_ && this.sections_.length > 0
            ? [...this.sections_]
            : [],
        selected_study_units:
          this.study_units_ && this.study_units_.length > 0
            ? [...this.study_units_]
            : [],
        selected_cohorts:
          this.cohorts_ && this.cohorts_.length > 0 ? [...this.cohorts_] : [],
        selected_cycles:
          this.cycles_ && this.cycles_.length > 0 ? [...this.cycles_] : [],
        selected_student_status:
          this.student_status_ && this.student_status_.length > 0
            ? [...this.student_status_]
            : [],
      };
      showLoadingAlert(
        "Generando Reporte...",
        "Por favor, espera mientras se genera el reporte."
      );
      if (this.selected_report_type == 1) {
        this.loading = true;
        this.fetchRangeGradesSections();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
      if (this.selected_report_type == 2) {
        this.loading = true;
        this.report_key++;
        this.chartdata = [];
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
      if (this.selected_report_type == 3) {
        this.loading = true;
        this.report_key++;
        this.chartdata = [];
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
      if (this.selected_report_type == 4) {
        this.loading = true;
        this.report_key++;
        this.chartdata = [];
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    fetchRangeGradesSections() {
      this.report_key++;
      this.show_range_grades_section = true;
      this.chartdata = [];
    },
  },
  watch: {
    selected_report_type() {
      this.clearFilters();
      // Ejemplo para mostrar todos los selectores como requeridos.
      // this.required_selectors = "campuses careers egress_profiles matters sections levels periods modalities";
      this.chartdata = null;
      this.resetSettings();
      if (this.selected_report_type == 1) {
        this.max_egress_profiles = 1;
        this.max_matters = 1;
        // this.max_sections = 1;
        this.max_periods = 1;
        this.max_levels = 1;
        this.show_selectors =
          "campuses egress_profiles levels periods matters sections";
        this.required_selectors =
          "campuses egress_profiles periods levels matters sections";
      }
      if (this.selected_report_type == 2) {
        this.max_egress_profiles = 1;
        this.max_cohorts = 1;
        this.show_selectors =
          "campuses egress_profiles cohorts student_status cycles";
        this.required_selectors =
          "campuses egress_profiles cohorts student_status";
      }
      if (this.selected_report_type == 3) {
        this.max_egress_profiles = 1;
        this.max_cohorts = 1;
        this.show_selectors = "campuses egress_profiles cohorts student_status";
        this.required_selectors =
          "campuses egress_profiles cohorts student_status";
      }
      if (this.selected_report_type == 4) {
        this.max_egress_profiles = 1;
        this.max_cohorts = 1;
        this.max_matters = 1;
        this.max_levels = 1;
        this.max_periods = 1;
        this.show_selectors =
          "campuses egress_profiles cohorts levels matters periods sections";
        this.required_selectors =
          "campuses egress_profiles cohorts levels matters periods sections";
      }
    },
  },
  mounted() {},
  created() {
    this.$hasPermissions();
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_MODALITIES);
    this.$store.dispatch(names.FETCH_PERIODS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS);
    this.$store.dispatch(names.FETCH_CYCLES);
  },
};
</script>

<style scoped>
.reports-default-image {
  width: 30%;
  height: 30%;
}
.w-10 {
  width: 10%;
}
.w-90 {
  width: 90%;
}
</style>